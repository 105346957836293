import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { SentryService } from 'src/app/utils/sentry/sentry.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Component({
  selector: 'app-usuario-pass-change',
  templateUrl: './usuario-pass-change.component.html',
  styleUrls: ['./usuario-pass-change.component.scss'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({ transform: 'scale3d(.3, .3, .3)' }),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))
      ])
    ])
  ]
})
export class UsuarioPassChangeComponent implements OnInit {
  @Input() primeraVez: boolean = false;
  @Input() oldPass: string = '';
  form: FormGroup = new FormGroup({});
  obscurePwd: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private alerts: AlertService,
    public activeModal: NgbActiveModal,
    public service: SesionService,
    private sentryService: SentryService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      oldPass: ['', [Validators.required]],
      newPass: ['', [Validators.required]],
      repeatPass: ['', [Validators.required]],
    }, {
      validator: this.mustMatch('newPass', 'repeatPass')
    });
    this.form.patchValue({ oldPass: this.oldPass })
  }

  get f() { return this.form.controls; }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  guardar() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.loading.show();
      this.service.changePassword(this.form.value).subscribe({
        next: (res: any) => {
          this.loading.hide();
          this.alerts.addAlert('Contraseña actualizada con éxito.', 'success');
          this.activeModal.close();
        }, error: (err) => {
          console.error(err);
          this.loading.hide();
          this.sentryService.captureException(err.error);
          if (err.error.response) {
            this.alerts.addAlert(err.error.response, "danger");
          } else {
            this.alerts.addAlert("No se pudo cambiar la contraseña.", "danger");
          }
        }
      })
    } else {
      this.form.markAllAsTouched();
    }
  }

  changeObscurePwd() {
    this.obscurePwd = !this.obscurePwd;
  }
}
