import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// referencia https://stackoverflow.com/a/41187919/6237795

// @HostListener('window:beforeunload') para acciones de cambio de ruta del browser
// xyzAbcFuncion () {}
// para mantener la consistencia el nombre usado fue canDeactivate () {}
// como los modales bloquean el resto de la pantalla, este metodo se ha usado en todos los modales

// canDeactivate(), con este guard y definido en la ruta, para acciones de cambio de ruta en angular
// esto junto a @HostL... para aquellos registros que no usan modals

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate()
      ? true
      : // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
        // when navigating away from your angular app, the browser will show a generic warning message
        // see http://stackoverflow.com/a/42207299/7307355
        confirm('Se perderán los cambios no guardados. ¿Está seguro que desea salir?');
  }
}