<div class="login-background">
	<div class="contenedor" [@crop]>
		<div class="izquierda">
			<div class="d-flex flex-column justify-content-center align-items-center h-100">
				<label class="py-4" style="font-size: 60px; font-weight: bold; min-height: 0;">¡Hola de nuevo!</label>
				<img style="max-width: 80%; max-height: 100%; min-height: 0;" src="/assets/images/login_image.svg"
					class="imagen">
				<label style="font-size: 30px; font-weight: bold; min-height: 0;">Bienvenido/a a nuestro sistema</label>
			</div>
		</div>
		<div class="derecha">
			<div class="formulario">
				<form [formGroup]="loginForm">
					<div class="logo">
						<img src="./../../../assets/system/logo_login.png" alt="Logo">
					</div>
					<div class="label-instruccion">
						<label>Inicia sesión y mantén tu negocio en orden</label>
					</div>
					<div>
						<label for="email" class="label-input">Correo Electrónico</label>
						<input type="text" class="form-control" id="email" formControlName="email"
							[ngClass]="{'is-invalid': loginForm.controls['email'].errors && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)}">
						<div class="invalid-feedback mb-3" *ngIf=" loginForm.controls['email'].errors?.['required']">
							Ingrese un correo electrónico.
						</div>
						<div class="invalid-feedback mb-3" *ngIf=" loginForm.controls['email'].errors?.['email']">
							El correo electrónico no es válido.
						</div>
						<div class="mb-3"
							*ngIf="!loginForm.controls['email'].errors || !(loginForm.controls['email'].dirty || loginForm.controls['email'].touched)">
							<div>&nbsp;</div>
						</div>
					</div>

					<div>
						<div class=" w-100 d-flex justify-content-between ">
							<label class="label-input" for="password">Contraseña</label>
							<a class="text-end" type="button" (click)="recuperarContrasena()">¿Olvidó su contraseña?</a>
						</div>

						<div class="input-group">
							<input type="{{ obscurePwd ? 'password': 'text' }}" class="form-control" id="password"
								formControlName="password"
								[ngClass]="{'is-invalid': loginForm.controls['password'].errors && loginForm.controls['password'].touched}">
							<button class="btn btn-suffix" type="button" id="suffix" (click)="changeObscurePwd()"> <i
									[ngClass]="{ 'fa fa-eye-slash': obscurePwd, 'fa-solid fa-eye': !obscurePwd }"></i>
							</button>
						</div>

						<div class="mb-3">
							<div
								*ngIf="loginForm.controls['password'].errors && loginForm.controls['password'].touched">
								<label style="color: yellow; font-size: 14px;">Ingrese una contraseña.</label>
							</div>
							<div
								*ngIf="!loginForm.controls['password'].errors || !(loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
								&nbsp;</div>
						</div>
					</div>

					<div class="d-flex flex-column w-100 my-4" style="gap: .5rem">
						<button type="button" class="btn btn-login shadow-sm"
							style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px;"
							(click)="iniciarSesion()">Iniciar Sesión</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>