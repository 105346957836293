<div class="modal-header">
	<h4 class="modal-title">Cambiar Contraseña</h4>
	<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
	</button>
</div>
<div class="modal-body mx-3 my-1">
	<form [formGroup]="form">
		<div class="form-group" *ngIf="!primeraVez">
			<label for="oldPass">Contraseña Actual</label>

			<div class="input-group">
				<input type="{{ obscurePwd ? 'password': 'text' }}" autocomplete="off" class="form-control" id="oldPass"
					formControlName="oldPass"
					[ngClass]="{'is-invalid': form.controls['oldPass'].errors && form.controls['oldPass'].touched}">
				<button class="btn btn-outline-secondary" type="button" id="suffix" (click)="changeObscurePwd()"> <i
						[ngClass]="{ 'fa fa-eye-slash': obscurePwd, 'fa-solid fa-eye': !obscurePwd }"></i>
				</button>
			</div>
			
			<div class="mb-1">
				<div *ngIf="form.controls['oldPass'].errors && form.controls['oldPass'].touched">
					<label style="color: #dc3545; font-size: 14px;">La contraseña no puede ser vacía.</label>
				</div>
				<div
					*ngIf="!form.controls['oldPass'].errors || !(form.controls['oldPass'].dirty || form.controls['oldPass'].touched)">
					&nbsp;</div>
			</div>
		</div>
		<div class="form-group">
			<label for="newPass">Nueva Contraseña</label>
			<input type="password" autocomplete="off" class="form-control" id="newPass" formControlName="newPass"
				[ngClass]="{'is-invalid': f['newPass'].errors && f['newPass'].touched}">
			<div *ngIf="f['newPass'].errors" class="invalid-feedback">
				<div *ngIf="f['newPass'].errors['required']">La contraseña no puede ser vacía.</div>
			</div>
			<div class="mb-4" *ngIf="!f['newPass'].errors || !f['newPass'].touched">
			</div>
		</div>
		<div class="form-group">
			<label for="repeatPass">Repetir Contraseña</label>
			<input type="password" autocomplete="off" class="form-control" id="repeatPass" formControlName="repeatPass"
				[ngClass]="{'is-invalid': f['repeatPass'].errors && f['repeatPass'].touched}">
			<div *ngIf="f['repeatPass'].errors" class="invalid-feedback">
				<div *ngIf="f['repeatPass'].errors['required']">La contraseña no puede ser vacía.</div>
				<div *ngIf="f['repeatPass'].errors['mustMatch']">Las contraseñas no coinciden.</div>
			</div>
			<div class="mb-4" *ngIf="!f['repeatPass'].errors || !f['repeatPass'].touched">
			</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-success" (click)="guardar()">Guardar</button>
	<button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cancel click')">Cancelar</button>
</div>