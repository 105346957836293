import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { FormatterService } from '../formatter/formatter.service';

@Component({
  selector: 'app-version-update',
  templateUrl: './version-update.component.html',
  styleUrls: ['./version-update.component.scss']
})
export class VersionUpdateComponent implements OnInit {

  @Input() md: any = null;
  @Input() version: string = '';

  constructor(
    private loading: LoadingService,
    private alerts: AlertService,
    public activeModal: NgbActiveModal,
    public formatter: FormatterService
  ) {}
  
  ngOnInit() {
  }
}
