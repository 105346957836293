// fbuild test kuv-f-toherba-dev
export const environment = {
  production: false,
  envName: 'test',
  title: 'Production Environment Heading',
  apiURL: 'https://kuvesoft.xyz:4040/',
  kPagosURL: 'https://kpagos.kuvesoft.xyz/',
  habilitarSuscripciones: true,
  version: '1.5.0'
};
