import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: "https://9eaba85280dcd9d45064d09c19a1c61b@o1138014.ingest.sentry.io/4506111451594753",
  integrations: [
    new Sentry.BrowserTracing({
      enableHTTPTimings: true,
    }),
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
      maxReplayDuration: 900000, // 15 Minutos de replay como máximo
      networkDetailAllowUrls: [window.location.origin],
    }),
  ],
  beforeSend(event) {
    // Verifica si el evento es una excepción y si es un objeto sin procesar
    if (event.exception && typeof event.exception.values === 'object') {
      const exception = event.exception.values[0];
      // Puedes agregar más lógica aquí para capturar más información
      exception.value = JSON.stringify(exception.value);
    }
    return event;
  },
  environment: environment.envName, // Env Sentry
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: `lubrikuv@${environment.version}`
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
