import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DriveStep, Driver, driver } from 'driver.js';

export const driverBase = driver({
  showProgress: true,
  allowClose: false,
  animate: true,
  disableActiveInteraction: true,
  popoverOffset: 15,
  smoothScroll: true,
  showButtons: ['next', 'previous', 'close'],
  doneBtnText: 'Hecho',
  nextBtnText: 'Siguiente',
  prevBtnText: 'Atrás',
  progressText: '{{current}} de {{total}}',
  onPopoverRender: (popover, { config, state }) => {
    if (driverBase.isLastStep()) return;

    const exitButton = document.createElement("button");
    exitButton.innerText = "Salir";
    popover.footerButtons.prepend(exitButton);
    exitButton.addEventListener("click", () => {
      driverBase.destroy();
    });
  },
});

export enum TutorialSteps {
  CREAR_OT,
  CREAR_VENTA,
  SIDEBAR,
  CREAR_SERVICIO,
  CREAR_PRODUCTO,
  INDEX_OT,
  INDEX_VENTA,
};

export const indexVentaSteps = (driver: Driver): DriveStep[] => {
  return [
    {
      element: '.registrar-venta-tutorial',
      popover: {
        title: 'Punto de Venta',
        description: `
          ● Crea una nueva venta solo de Productos.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '#filtros',
      popover: {
        title: 'Filtros de Ventas',
        description: `
          ● Filtra utilizando los campos que estimes necesarios.
        `,
        side: "top",
        align: 'center',
        // ligera copia en onPrevClick de unos pasos adelante
        onNextClick: (element, step, options) => {
          const i = driver.getActiveIndex();
          if (!i) return;
          const steps = driver.getConfig().steps;
          if (!steps) return;
          const selectorNextStep = steps[i + 1].element;
          if (!selectorNextStep) return;
          const filtrosElement = typeof selectorNextStep !== 'string' ? (selectorNextStep as HTMLElement) : document.querySelector<HTMLElement>(selectorNextStep);
          if (!filtrosElement) {
            driver.moveTo(i+2);
            return;
          }
          driver.moveNext();
        }
      },
    },
    {
      element: '#filtros .btns',
      popover: {
        title: 'Filtrar y exportar',
        description: `
          ● <span class="fw-bold">Limpiar Filtros: </span> Borra los filtros de búsquedas aplicados. <br>
          ● <span class="fw-bold">Aplicar filtros: </span> Aplica los filtros para que el sistema muestre la información deseada. <br>
          ● <span class="fw-bold">Descargar Excel: </span> Exporta la información de acuerdo a los filtros aplicados previamente. <br>
        `,
        side: "left",
        align: 'center',

      },
    },
    {
      element: '.kuv-lazy-table .grid',
      popover: {
        title: 'Lista de Ventas',
        description: `
          ● Administra todas las Ventas de productos registradas.
        `,
        side: "top",
        align: 'center',
        // ligera copia en onNextClick unos pasos arriba
        onPrevClick: (element, step, options) => {
          const i = driver.getActiveIndex();
          if (!i) return;
          const steps = driver.getConfig().steps;
          if (!steps) return;
          const selectorPrevStep = steps[i - 1].element;
          if (!selectorPrevStep) return;
          const filtrosElement = typeof selectorPrevStep !== 'string' ? (selectorPrevStep as HTMLElement) : document.querySelector<HTMLElement>(selectorPrevStep);
          if (!filtrosElement) {
            driver.moveTo(i-2);
            return;
          }
          driver.movePrevious();
        }
      },
    },
    {
      element: '.kuv-lazy-table .grid .acciones-list',
      popover: {
        title: 'Acciones de OT',
        description: `
          ● <span class="fw-bold">Ver:</span> Visualiza el perfil de la Venta. <br>
          ● <span class="fw-bold">Editar:</span> Actualiza la información de la Venta. <br>
          ● <span class="fw-bold">Documento:</span> Descarga en PDF el documento y la envía al correo de tu cliente. <br>
          ● <span class="fw-bold">Eliminar:</span> Elimina una Venta del sistema y repone los productos al inventario. <br>
        `,
        side: "left",
        align: 'center',

      },
    },
  ]
};

export const indexOTSteps = (driver: Driver): DriveStep[] => {
  return [
    {
      element: '.registrar-orden-tutorial',
      popover: {
        title: 'Registrar Nueva OT',
        description: `
          ● Crear una nueva OT.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.flujo-ot-tutorial',
      popover: {
        title: 'Flujo OT',
        description: `
          ● Puedes ver las OT separadas por estados.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.revisiones-por-renovar-tutorial',
      popover: {
        title: 'Revisiones por revonar',
        description: `
          ● Puedes ver un listado o calendario de las próximas mantenciones.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '#filtros',
      popover: {
        title: 'Filtros OT',
        description: `
          ● Filtra utilizando los campos que estimes necesarios.
        `,
        side: "top",
        align: 'center',
        // ligera copia en onPrevClick de unos pasos adelante
        onNextClick: (element, step, options) => {
          const i = driver.getActiveIndex();
          if (!i) return;
          const steps = driver.getConfig().steps;
          if (!steps) return;
          const selectorNextStep = steps[i + 1].element;
          if (!selectorNextStep) return;
          const filtrosElement = typeof selectorNextStep !== 'string' ? (selectorNextStep as HTMLElement) : document.querySelector<HTMLElement>(selectorNextStep);
          if (!filtrosElement) {
            driver.moveTo(i+3);
            return;
          }
          driver.moveNext();
        }
      },
    },
    {
      element: '#filtros .btns button:nth-child(2)',
      popover: {
        title: 'Aplicar filtros',
        description: `
          ● Presiona para que el sistema muestre la información deseada.
        `,
        side: "left",
        align: 'center',

      },
    },
    {
      element: '#filtros .btns button:nth-child(3)',
      popover: {
        title: 'Exportar información',
        description: `
          ● Exporta la información de acuerdo a los filtros aplicados previamente.
        `,
        side: "left",
        align: 'center',
      },
    },
    {
      element: '.kuv-lazy-table .grid',
      popover: {
        title: 'Lista de OT',
        description: `
          ● Administra todas las OT registradas con sus respectivos estados.
        `,
        side: "top",
        align: 'center',
        // ligera copia en onNextClick unos pasos arriba
        onPrevClick: (element, step, options) => {
          const i = driver.getActiveIndex();
          if (!i) return;
          const steps = driver.getConfig().steps;
          if (!steps) return;
          const selectorPrevStep = steps[i - 1].element;
          if (!selectorPrevStep) return;
          const filtrosElement = typeof selectorPrevStep !== 'string' ? (selectorPrevStep as HTMLElement) : document.querySelector<HTMLElement>(selectorPrevStep);
          if (!filtrosElement) {
            driver.moveTo(i-3);
            return;
          }
          driver.movePrevious();
        }
      },
    },
    {
      element: '.kuv-lazy-table .grid .acciones-list',
      popover: {
        title: 'Acciones de OT',
        description: `
          ● <span class="fw-bold">Ver:</span> Visualiza el perfil de la OT. <br>
          ● <span class="fw-bold">Editar:</span> Actualiza la información de la OT. <br>
          ● <span class="fw-bold">Adjuntos:</span> Carga imágenes o archivos PDF a la OT. <br>
          ● <span class="fw-bold">Estado:</span> Cambia el estado de una OT a "Pendiente", "En Proceso", "Completada". <br>
          ● <span class="fw-bold">Documento:</span> Descarga en PDF el documento y la envía al correo de tu cliente. <br>
          ● <span class="fw-bold">Eliminar:</span> Elimina una OT del sistema y repone los productos al inventario. <br>
        `,
        side: "left",
        align: 'center',

      },
    },
  ]
};

export const crearOTSteps = (driver: Driver): DriveStep[] => {
  return [
    {
      element: '.seccion-detalles-tutorial',
      popover: {
        title: 'Detalles',
        description: `
          ● Debe rellenar los campos no opcionales.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.vehiculo-tutorial',
      popover: {
        title: 'Vehículo',
        description: `
          ● Puede seleccionar su vehículo. <br>
          ● Puede editar un vehículo seleccionado. <br>
          ● Puede crear un nuevo vehículo. <br>`,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.cliente-tutorial',
      popover: {
        title: 'Cliente',
        description: `
          ● Puede seleccionar su cliente. <br>
          ● Puede editar un cliente seleccionado. <br>
          ● Puede crear un nuevo cliente.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.seccion-venta-tutorial',
      popover: {
        title: 'Ventas',
        description: `
          ● Sección para agregar servicios/productos.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.crear-productos-tutorial',
      popover: {
        title: 'Crear',
        description: `
          ● Puede crear productos y/o servicios.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.cargar-fotos-vehiculo-tutorial',
      popover: {
        title: 'Cargar fotos',
        description: `
          ● Puede cargar fotos de su vehículo.
        `,
        side: 'left',
        align: 'start',
      }
    },
    {
      element: '.agregar-productos-tutorial',
      popover: {
        title: 'Agregar Productos',
        description: `
          ● Para agregar productos a su venta.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.cambiar-descuento-tutorial',
      popover: {
        title: 'Tipo de Descuento',
        description: `
          ● El descuento puede ser por pesos o por % al hacer click en este botón.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.descuento-tutorial',
      popover: {
        title: 'Descuento',
        description: `
          ● Puede agregar un descuento al bruto de la venta.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.tipo-documento-tutorial',
      popover: {
        title: 'Tipo de Documento',
        description: `
          ● Las cotizaciones no descuentan stock de inventario.
        `,
      },
    },
    {
      element: '.metodo-pago-tutorial',
      popover: {
        title: 'Método de Pago',
        description: `
          ● Deshabilitado en las cotizaciones.
        `,
      },
    },
    {
      element: '.registrar-venta-tutorial',
      popover: {
        title: 'Registrar Venta',
        description: `
          ● Para guardar su venta.
        `,
      },
    },
  ];
};

export const crearVentaSteps = (driver: Driver): DriveStep[] => {
  return [
    {
      element: '.seccion-detalles-tutorial',
      popover: {
        title: 'Detalles',
        description: `
          ● Debe rellenar los campos no opcionales.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.cliente-tutorial',
      popover: {
        title: 'Cliente',
        description: `
          ● Puede seleccionar su cliente. <br>
          ● Puede editar un cliente seleccionado. <br>
          ● Puede crear un nuevo cliente.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.seccion-venta-tutorial',
      popover: {
        title: 'Ventas',
        description: `
          ● Sección para agregar productos.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.crear-productos-tutorial',
      popover: {
        title: 'Crear',
        description: `
          ● Puede crear nuevos productos.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.agregar-productos-tutorial',
      popover: {
        title: 'Agregar Productos',
        description: `
          ● Para agregar productos a su venta.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.descuento-tutorial',
      popover: {
        title: 'Descuento',
        description: `
          ● Puede agregar un descuento al bruto de la venta.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.cambiar-descuento-tutorial',
      popover: {
        title: 'Tipo de Descuento',
        description: `
          ● El descuento puede ser por pesos o por % al hacer click en este botón.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.tipo-documento-tutorial',
      popover: {
        title: 'Tipo de Documento',
        description: `
          ● Las cotizaciones no descuentan stock de inventario.
        `,
      },
    },
    {
      element: '.metodo-pago-tutorial',
      popover: {
        title: 'Método de Pago',
        description: `
          ● Deshabilitado en las cotizaciones.
        `,
      },
    },
    {
      element: '.registrar-venta-tutorial',
      popover: {
        title: 'Registrar Venta',
        description: `
          ● Para guardar su venta.
        `,
      },
    },
  ];
};

export const sidebarSteps = (driver: Driver): DriveStep[] => {
  return [
    {
      element: 'kuv-sidebar-item:has(.fas.fa-chart-bar)',
      popover: {
        title: 'Reportes de Ventas',
        description: `
          ● Puede visualizar los reportes de su sistema.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: 'kuv-sidebar-item:has(.fas.fa-car)',
      popover: {
        title: 'Taller',
        description: `
          ● Órdenes de Trabajo. <br>
          ● Flujo OT. <br>
          ● Vehículos y Servicios.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: 'kuv-sidebar-item:has(.fas.fa-boxes)',
      popover: {
        title: 'Inventario',
        description: `
          ● Productos y Categorías. <br>
          ● Stock Crítico. <br>
          ● Ajustes de Stock.
        `,
        side: "right",
        align: 'start',
      },
    },
  ];
};

export const crearServicioSteps = (driver: Driver, activeModal: NgbModal): DriveStep[] => {
  return [
    {
      element: '.agregar-servicio-tutorial',
      popover: {
        title: 'Agregar Servicio',
        description: `
          ● Para agregar un nuevo servicio.
        `,
        side: "right",
        align: 'start',
        onNextClick: (element, step, options) => {
          (document.querySelector('.agregar-servicio-tutorial') as HTMLButtonElement)?.click();
          setTimeout(() => {
            driver.moveNext();
          }, 0);
        },
      },
    },
    {
      element: '.nombre-servicio-tutorial',
      popover: {
        title: 'Nombre del Servicio',
        description: `
          ● Obligatorio.
        `,
        side: "right",
        align: 'start',
        onPrevClick: () => {
          activeModal.dismissAll();
          driver.movePrevious();
        }
      },
    },
  ];
};

export const crearProductoSteps = (driver: Driver, activeModal: NgbModal): DriveStep[] => {
  return [
    {
      element: '.agregar-producto-tutorial',
      popover: {
        title: 'Agregar Producto',
        description: `
          ● Para agregar un nuevo producto.
        `,
        onNextClick: (element, step, options) => {
          (document.querySelector('.agregar-producto-tutorial') as HTMLButtonElement)?.click();
          setTimeout(() => {
            driver.moveNext();
          }, 0);
        },
        align: 'start',
      },
    },
    {
      element: '.nombre-producto-tutorial',
      popover: {
        title: 'Nombre del Producto',
        description: `
          ● El nombre del producto es obligatorio.
        `,
        side: "right",
        align: 'start',
        onPrevClick: () => {
          activeModal.dismissAll();
          driver.movePrevious();
        },
        onNextClick: () => {
          driver.moveNext();
        }
      },
    },
    {
      element: '.precio-venta-tutorial',
      popover: {
        title: 'Precio de Venta',
        description: `
          ● Al ingresar un precio, se calcula el otro tras hacer click afuera.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.precio-compra-tutorial',
      popover: {
        title: 'Precio de Compra',
        description: `
          ● Al ingresar un precio, se calcula el otro tras hacer click afuera.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.stock-inicial-tutorial',
      popover: {
        title: 'Stock Inicial',
        description: `
          ● (Opcional) Se asigna el stock cuando se crea una sucursal .
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.stock-minimo-tutorial',
      popover: {
        title: 'Stock Mínimo',
        description: `
          ● (Opcional) Para resaltar con rojo si el stock es inferior.
        `,
        side: "right",
        align: 'start',
      },
    },
    {
      element: '.imagen-tutorial',
      popover: {
        title: 'Imagen',
        description: `
          ● (Opcional) Imagen del producto .
        `,
        side: "right",
        align: 'start',
      },
    },
  ];
};