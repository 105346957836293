<div class="form-group">
    <ng-select [(ngModel)]="selectedItem" [items]="sesion.routesForSearch" [searchFn]="customSearchFn" bindLabel="text" placeholder="Buscar..."
        (change)="onItemSelected($event)">
        <ng-template ng-label-tmp let-item="item">
            <label>{{item.text}}</label>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
            <div style="display: flex; align-items: center; gap: 10px;">
                <div style="flex: 1;">
                    <i [class]="'fa fa-' + item.icon"
                    style="color: #595959; font-size: 20px;"></i>
                </div>
                <div style="flex: 12;">
                    <p style="margin: 0; font-weight: 500;">{{item.text}}</p>
                    <p style="margin: 0; font-size: 12px;" class="text-wrap">{{item.description}}</p>
                </div>
            </div>
        </ng-template>
    </ng-select>
</div>