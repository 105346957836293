import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { driver } from "driver.js";
import { Router } from '@angular/router';
import { crearOTSteps, crearProductoSteps, crearServicioSteps, crearVentaSteps, driverBase, sidebarSteps } from 'src/app/utils/tutorial/steps';

@Component({
  selector: 'app-contacto-soporte',
  templateUrl: './contacto-soporte.component.html',
  styleUrls: ['./contacto-soporte.component.scss'],
})
export class ModalContactoSoporteComponent implements OnInit {

  @Input() soloDemo: boolean = true;
  @Input() showTutorialButton: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    public modalService: NgbModal,
  ) { }

  ngOnInit() { }

  guardar() {
    this.activeModal.close(true);
  }

  cancelar() {
    this.activeModal.dismiss();
  }

  email(): void {
    const mailText = "mailto:soporte@kuvemar.com+?subject=files&body=";
    window.location.href = mailText;
    this.activeModal.dismiss();
  }

  whatsApp(): void {
    const whatsApp = "https://api.whatsapp.com/send?phone=56944758039";
    window.location.href = whatsApp;
    this.activeModal.dismiss();
  }

  whatsAppKuvemar(): void {
    const whatsApp = "https://api.whatsapp.com/send?phone=56975155189";
    window.location.href = whatsApp;
    this.activeModal.dismiss();
  }

  tel(): void {
    const tel = "tel:+56975155189";
    window.location.href = tel;
    this.activeModal.dismiss();
  }

  iniciarTutorial(): void {
    this.activeModal.close();

    const driverObj = driverBase;

    // ej: /revisiones/create
    const url = this.router.url;
    // orden del if importa
    if (url.includes('revisiones/create-venta')) {
      driverObj.setSteps(crearVentaSteps(driverObj));
    } else if (url.includes('revisiones/create')) {
      driverObj.setSteps(crearOTSteps(driverObj));
    } else if (url.includes('servicio/index')) {
      driverObj.setSteps(crearServicioSteps(driverObj, this.modalService));
    } else if (url.includes('producto/index')) {
      driverObj.setSteps(crearProductoSteps(driverObj, this.modalService));
    } else {
      driverObj.setSteps(sidebarSteps(driverObj));
    }

    driverObj.drive();
  }
}
