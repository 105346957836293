<div class="modal-header">
    <h4 class="modal-title">¡Nueva Actualización!</h4>
</div>
<div class="modal-body">
    <div class="w-100 d-flex justify-content-center">
        <img style="max-width: 200px;" src="./assets/images/imagen-nueva-version.png" alt="Nueva Versión">
    </div>
    <markdown [data]="md"></markdown>
</div>
<div class="modal-footer">
    <button class="btn text-white" (click)="activeModal.dismiss('Cross click')">¡LISTO!</button>
</div>