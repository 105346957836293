<div class="modal-content border-0 rounded">
    <div class="modal-header m-0" style="background-color: #2563FD; color:white;">
        <h4 class="modal-title">Enviar una sugerencia</h4>
        <button type="button" class="btn-close" style="color: rgba(255,255,255,.5)" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <h5>Agradecemos tu feedback para continuar mejorando este producto para ti.</h5>
        <br>
        <form [formGroup]="form">
            <div class="mb-3">
                <label for="titulo">Título</label>
                <input type="text" class="form-control" id="titulo" formControlName="titulo" [ngClass]="{'is-invalid': form.controls['titulo'].errors && form.controls['titulo'].touched}">
            </div>
            <div class="mb-3">
                <label for="texto">Sugerencia</label>
                <textarea class="form-control" id="texto" rows="6" formControlName="texto" [ngClass]="{'is-invalid': form.controls['texto'].errors && form.controls['texto'].touched}"></textarea>
            </div>
            
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="guardar()">Enviar</button>
        <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('Cancel click')">Cancelar</button>
    </div>
</div>