<div class="modal-header">
    <h4 class="modal-title">{{titulo}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <h1 id="modal-title" class="m-3">{{texto}}</h1>
    <br>
    <h1 id="modal-title" class="m-3">{{detalle}}</h1>
</div>
<div class="modal-footer">
    <button *ngIf="!soloVolver" type="button" class={{class_boton}} (click)="guardar()">{{textoAceptar}}</button>
    <button type="button" class="btn btn-secondary" (click)="cancelar()">{{soloVolver ? 'Volver' :
        textoCancelar}}</button>
</div>