import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { ConfigService } from '../config/config.service';
import { Socket } from 'socket.io-client';
import { SesionService } from '../sesion/sesion.service';
import { AlertService } from 'ngx-kuv-tools';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  /**
   * Instancia del Socket.
   */
  private socket: Socket | undefined;

  /**
   * Instancia del Active Modal.
   */
  private activeModalInstance: NgbActiveModal | undefined;

  constructor(
    private config: ConfigService,
    private sesion: SesionService,
    private alerts: AlertService,
  ) { }

  /**
   * Inicia la conexión con Socket para ser usada durante el Paywall. 
   * El método configura los callback para cuando se finalize correctamente el proceso de pago.
   * @param callback Método que será ejecutado al finalizar el evento de compra finalizada.
   */
  startConnection(callback: any): void {
    // Conectar al servidor Node.js
    this.socket = io(this.config.urlBase);

    // Manejar eventos del servidor
    this.socket.on(`message-${this.sesion.user.empresa_id}`, (mensaje: string) => {
      if (mensaje == 'payment-finished-successfully') {
        this.sesion.goToHome();
        if (this.activeModalInstance) this.activeModalInstance?.close();
        this.alerts.addAlert("El pago de su suscripción LubriKuv se ha realizado correctamente.", "success");
        this.activeModalInstance = undefined;
        setTimeout(() => {
          window.location.reload();
        }, 800);
      } else {
        if (callback) callback();
      }
    });
  }

  /**
   * Desconecta al usuario de la conexión socket.
   */
  disconnect(): void {
    this.socket?.disconnect();
    this.socket?.close();
  }

  /**
   * Envía un mensaje al servidor vía socket.
   * @param mensaje Mensaje a enviar.
   */
  enviarMensaje(mensaje: string): void {
    // Enviar mensaje al servidor
    this.socket!.emit('mensaje', mensaje);
  }

  /**
   * Asigna la instancia del Active Modal para luego ser cerrada durante los callback.
   * @param activeModal Instancia de Ventana Modal activa.
   */
  setActiveModalInstanceForClose(activeModal: NgbActiveModal): void {
    this.activeModalInstance = activeModal;
  }
}
