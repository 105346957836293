<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <markdown [data]="md"></markdown>
    </div>
    <div class="modal-footer">
        <div class="d-flex justify-content-center w-100 gap-3">
            <button type="button" class="btn btn-primary" (click)="aceptar()">Aceptar</button>
            <button type="button" class="btn btn-danger" (click)="activeModal.dismiss('Cross click')">Rechazar</button>
        </div>
    </div>
</div>