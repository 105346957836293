import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/utils/config/config.service';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Component({
  selector: 'app-sugerencia',
  templateUrl: './sugerencia.component.html',
  styleUrls: ['./sugerencia.component.scss']
})
export class SugerenciaComponent implements OnInit {

  form: FormGroup = new FormGroup({});
  
  constructor(
    private formBuilder: FormBuilder,
    private loading: LoadingService,
    private alerts: AlertService,
    public activeModal: NgbActiveModal,
    public formatter: FormatterService,
    private http: HttpClient,
    private config: ConfigService,
    private sesion: SesionService,
  ) {}
  
  ngOnInit() {
    this.form = this.formBuilder.group({
      texto: ['', [Validators.required]],
      titulo: ['', [Validators.required]],
    });
  }
  
  guardar(){
    if(this.form.valid){
      this.loading.show();
      this.http.post(this.config.apiUrl + 'sugerencia/create', this.form.value, this.sesion.headers).subscribe({ next: (res) => {
        this.loading.hide();
        this.alerts.addAlert("Sugerencia enviada correctamente.", "success");
        this.activeModal.close(res);
      }, error: (err) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("No se pudo guardar la sugerencia.", "danger");
      }})
    } else {
      this.form.markAllAsTouched();
    }
  }

}
