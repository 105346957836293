<div class="login-background">
	<div *ngIf="!pwdCambiado" class="contenedor" [@crop]>
		<div class="izquierda">
			<div class="h-100 d-flex flex-column justify-content-center align-items-center">
				<div class="label-intro">
					<label>Restablecer Contraseña</label>
					<br>
					<label>LubriKuv</label>
				</div>
				<img src="/assets/images/pwd-recovery.svg" class="imagen">
				<div class="label-intro-2">
					<label></label>
				</div>
			</div>
		</div>
		<div class="derecha">
			<div class="formulario">
				<form [formGroup]="form">
					<div class="logo">
						<img src="./../../../assets/system/logo_login.png" alt="Logo">
					</div>
					<div class="label-instruccion">
						<label>Ingrese una nueva contraseña para su cuenta de LubriKuv </label>
					</div>
					<div>
						<label for="newPass" class="label-input">Nueva Contraseña</label>

						<div class="input-group">
							<input type="{{ obscurePwd ? 'password': 'text' }}" class="form-control" id="newPass"
								formControlName="newPass"
								[ngClass]="{'is-invalid': form.controls['newPass'].errors && form.controls['newPass'].touched}">
							<button class="btn btn-suffix" type="button" id="suffix" (click)="changeObscurePwd()"> <i
									[ngClass]="{ 'fa fa-eye-slash': obscurePwd, 'fa-solid fa-eye': !obscurePwd }"></i>
							</button>
						</div>

						<div class="mb-3">
							<div *ngIf="form.controls['newPass'].errors && form.controls['newPass'].touched">
								<label style="color: yellow; font-size: 14px;">Debe ingresar una contraseña.</label>
							</div>
							<div
								*ngIf="!form.controls['newPass'].errors || !(form.controls['newPass'].dirty || form.controls['newPass'].touched)">
								&nbsp;</div>
						</div>
					</div>

					<div>
						<label for="repeatPass" class="label-input">Repita la Nueva Contraseña</label>
						<input type="password" class="form-control" id="repeatPass" formControlName="repeatPass"
							[ngClass]="{'is-invalid': form.controls['repeatPass'].errors && (form.controls['repeatPass'].dirty || form.controls['repeatPass'].touched)}">
						<div class="invalid-feedback mb-3" *ngIf=" form.controls['repeatPass'].errors?.['required']">
							Debe ingresar una nueva contraseña.
						</div>
						<div class="invalid-feedback mb-3" *ngIf=" form.controls['repeatPass'].errors?.['mustMatch']">
							Las contraseñas deben coincidir.
						</div>
						<div class="mb-3"
							*ngIf="!form.controls['repeatPass'].errors || !(form.controls['repeatPass'].dirty || form.controls['repeatPass'].touched)">
							<div>&nbsp;</div>
						</div>
					</div>

					<div class="d-flex flex-column w-100 my-4" style="gap: .5rem">
						<button type="button" class="btn btn-login shadow-sm"
							style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px"
							(click)="recuperarContrasena()">Cambiar Contraseña</button>
						<br>
						<button type="button" class="btn btn-back "
							style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px"
							(click)="iniciarSesion()">Cancelar</button>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div *ngIf="pwdCambioTerminado" class="contenedor" [@crop]>
		<div class="izquierda">
			<div>
				<div class="label-intro">
					<label>Recuperación de Cuenta</label>
					<br>
					<label>LubriKuv</label>
				</div>
				<img src="/assets/images/pwd-changed.svg" class="imagen">
				<div class="label-intro-2">
					<label></label>
				</div>
			</div>
		</div>
		<div class="derecha">
			<div class="formulario">
				<div class="logo">
					<img src="./../../../assets/system/logo_login.png" alt="Logo">
				</div>
				<div class="label-instruccion">
					<label>¡Su contraseña ha sido cambiada exitosamente!</label>
					<br>
					<label>Ya puede acceder a Lubricentro con su nueva contraseña.</label>
				</div>

				<div class="d-flex flex-column w-100 my-4" style="gap: .5rem">
					<button type="button" class="btn btn-login shadow-sm"
						style="font-weight: bold;font-family: Helvetica, Arial, sans-serif;font-size: 16px"
						(click)="iniciarSesion()">Iniciar Sesión</button>
				</div>
				<br>
				<br>
				<br>
				<br>
			</div>
		</div>
	</div>
</div>