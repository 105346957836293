import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { SesionService } from 'src/app/utils/sesion/sesion.service';

@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss']
})
export class BusquedaComponent implements OnInit {
  selectedItem: any = null;

  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent | undefined;

  constructor(
    public sesion: SesionService,
    private router: Router,
  ) { }

  ngOnInit(): void { }

  customSearchFn(term: string, items: any): boolean {
    const nameText = items.text.replace(',', '');
    const termText = term = term.toLocaleLowerCase();
    const nameResult = nameText.toLocaleLowerCase().indexOf(termText) > -1;
    if (nameResult) return true;
    
    const descriptionText = items.description.replace(',', '');
    const descriptionResult = descriptionText.toLocaleLowerCase().indexOf(termText) > -1;
    if (descriptionResult) return true;

    const normalize = (str: string) => {
      return str
        .toLowerCase()
        .normalize('NFD') // Descompone caracteres acentuados en caracteres base + marcas diacríticas
        .replace(/[\u0300-\u036f]/g, '') // Elimina las marcas diacríticas
        .replace(/s$/, ''); // Elimina 's' al final para manejar singular y plural
    };
    const normalizedQuery = normalize(term);
    const queryWords = normalizedQuery.split(' ');
    const tagsResult = items.tags.some((item: string) => {
      const normalizedItem = normalize(item);
      return queryWords.every(word => normalizedItem.includes(word));
    });

    return tagsResult;
  }

  onItemSelected(event: any): void {
    const selectedItem = event;
    if (selectedItem && selectedItem.route) {
      this.router.navigate([selectedItem.route]);
      this.ngSelect?.clearModel();
      this.selectedItem = null;
    }
  }
}
