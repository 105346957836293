<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="onDismiss()"></button>
    </div>
    <div class="modal-body">
        <h4 class="text-center">Continúe con el pago a través de {{esPaisChile ? 'VentiPay' : 'dLocal Go'}}</h4>
        <div class="w-100 text-center d-flex flex-column gap-2 justify-content-center align-items-center">
            <img *ngIf="esPaisChile" class="my-4" style="max-width: 150px; max-height: 150px; cursor: pointer;"
                src="/assets/images/logo-venti.svg" alt="Payment in process">
            <img *ngIf="!esPaisChile" class="my-4" style="max-width: 150px; max-height: 150px; cursor: pointer;"
                src="/assets/images/logo-dlocalgo.png" alt="Payment in process">
        </div>
        <div class="d-flex gap-2 justify-content-center" *ngIf="sesion.user.rol_id == 0 || sesion.user.rol_id == 200">
            <div class="w-100 mb-3">
                <label for="enlace">Enlace: </label>
                <input type="text" class="form-control" id="enlace" [ngModel]="url">
            </div>
            <button class="btn btn-primary mb-3 mt-auto" (click)="copy()"><i class="fas fa-copy"></i>
            </button>
        </div>
        <div class="d-flex justify-content-center">
            <div class="my-3 btn btn-primary btn-lg px-4" (click)="abrirEnlacePago()">
                Ir al pago
            </div>
        </div>
        <div class="text-center">El pago podría tardar unos minutos en ser procesado.</div>
        <div class="text-center">Una vez finalizado el proceso de pago, si la información de su cuenta no se ha
            actualizado, puede cerrar esta ventana y recargar nuevamente.</div>
    </div>
    <div class="modal-footer">
    </div>
</div>