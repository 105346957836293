import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  captureException(err: any): void {
    try {
      if (!environment.production || !err) return;
      Sentry.captureException(err);
    } catch (error) { }
  }

  /**
   * 
   * Establece la información del usuario en Sentry para el seguimiento de errores y eventos.
   * 
   * @param {string | null} email - El correo electrónico del usuario. Si es `null` o vacío, se limpia la información del usuario en Sentry.
   * @param {number | null} empresa_id - El identificador de la empresa del usuario. Si es `null`, se limpia la información del usuario en Sentry.
  */
  setUser(email: string | null, empresa_id: number | null): void {
    if (!email || !empresa_id) Sentry.setUser(null);
    else Sentry.setUser({ email: email ?? '', empresa_id: empresa_id });
  }
}