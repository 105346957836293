import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { SesionService } from '../sesion/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  latestRoute: string = '';

  constructor(
    public router: Router,
    private sesion: SesionService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const expectedRoles: any[] = route.data['expectedRoles'];
    const ruta = route.url ? route.url[0].path : '';
    const routes = this.sesion.routes;

    const rol = this.sesion.rol;
    const token = this.sesion.token;

    if(ruta == '') return true;

    // if (this.sesion.user.rol_id == 0) return true;
    if (expectedRoles && expectedRoles.includes(rol) && token) return true;

    if (!routes || routes.length == 0) return true;

    for (let index = 0; index < routes.length; index++) {
      const route = routes[index];
      if(route.children) {
        for (let index = 0; index < route.children.length; index++) {
          const child = route.children[index];
          const steps = child.route.split('/');
          
          if(steps[0] == ruta) {
            this.latestRoute = ruta;
            return true;
          }
          if(steps.length > 1 && steps[1] == ruta) {
            this.latestRoute = ruta;
            return true;
          }
          if (child.route == ruta) {
            return true;
          }
          if (child.route == `${this.latestRoute}/${ruta}`) {
            return true;
          }
        }
      } else {
        const steps = route.route.split('/');
        if (steps[0] == ruta) {
          this.latestRoute = ruta;
          return true;
        }
        if (steps.length > 1 && steps[1] == ruta) {
          this.latestRoute = ruta;
          return true;
        }
        if (route.route == ruta) {
          return true;
        }
        if (route.route == `${this.latestRoute}/${ruta}`) {
          return true;
        }
      }
    }

    console.log('AUTH FAILED', ruta);
    this.router.navigateByUrl('/login');
    return false;

    if(rol == 0) return true;
    if (expectedRoles.includes(rol) && token) {
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }

}
