import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxKuvUtilsModule } from 'ngx-kuv-utils';
import { NgxKuvToolsModule } from 'ngx-kuv-tools';
import { CompraCreateSharedComponent } from './compra/compra-create-shared/compra-create-shared.component';
import { NgxKuvCreateVentaModule } from 'ngx-kuv-venta';
import { ModalContactoSoporteComponent } from './contacto-soporte/contacto-soporte.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SugerenciaComponent } from './sugerencia/sugerencia.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    CompraCreateSharedComponent,
    ModalContactoSoporteComponent,
    PageNotFoundComponent,
    SugerenciaComponent,
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    NgxKuvUtilsModule,
    NgxKuvToolsModule,
    NgxKuvCreateVentaModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[
    CompraCreateSharedComponent,
    ModalContactoSoporteComponent,
  ]
})
export class SharedModule { }
