import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService, AlertService } from 'ngx-kuv-tools';
import { FormatterService } from 'src/app/utils/formatter/formatter.service';
import { environment } from 'src/environments/environment';
import { SuscripcionesInformacionPlanesComponent } from '../suscripciones-informacion-planes/suscripciones-informacion-planes.component';
import { SesionService } from 'src/app/utils/sesion/sesion.service';
import { ModalDeleteComponent } from 'src/app/utils/modal-delete/modal-delete.component';
import { SuscripcionesService } from '../suscripciones.service';
import { SentryService } from 'src/app/utils/sentry/sentry.service';

@Component({
  selector: 'app-suscripciones-pago-pendiente',
  templateUrl: './suscripciones-pago-pendiente.component.html',
  styleUrls: ['./suscripciones-pago-pendiente.component.scss']
})
export class SuscripcionesPagoPendienteComponent implements OnInit {
  urlKpagos: string = environment.kPagosURL + 'suscripciones/mis-suscripciones';
  get enableSubscriptionUI(): boolean { return this.sesion.enableSubscriptionUI };

  get esCuentaPrueba(): boolean {
    return this.sesion.user.cuenta_prueba;
  }

  get plan(): number {
    return (this.sesion.user?.suscripcion?.plan_id);
  }

  get tipo(): number {
    return (this.sesion.user?.suscripcion?.tipo);
  }

  constructor(
    private loading: LoadingService,
    private alerts: AlertService,
    public activeModal: NgbActiveModal,
    public formatter: FormatterService,
    private modalService: NgbModal,
    private sesion: SesionService,
    private suscripcionesService: SuscripcionesService,
    private sentryService: SentryService,
  ) { }

  ngOnInit() {
  }

  renovarPlan(): void {
    this.loading.show();
    this.suscripcionesService.planActual().subscribe({
      next: (res: any) => {
        this.loading.hide();
        const modalRef = this.modalService.open(SuscripcionesInformacionPlanesComponent, { size: 'xl', scrollable: true, centered: true });
        modalRef.componentInstance.suscripcionPrevia = res.suscripcion;
        modalRef.result.then((result: any) => {
          this.activeModal.close();
        }, (reason: any) => {
          this.activeModal.close();
        });
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("Error al obtener la información.", "danger");
        this.sentryService.captureException(err.error);
      }
    });

  }

  openLink(): void {
    window.open(this.urlKpagos, '_blank');
  }

  cerrarSesion(): void {
    this.activeModal.close({ cerrarSesion: true });
  }

  pagarPlan(): void {
    this.realizarPagoPremium();
  }

  async realizarPagoPremium() {
    this.loading.show();
    let suscripcion_activa = await this.sesion.obtenerEstadoSuscripcion();
    this.loading.hide();
    if (suscripcion_activa) {
      const modalRef = this.modalService.open(ModalDeleteComponent, { size: 'lg', scrollable: true, centered: true });
      modalRef.componentInstance.texto = 'Ya posee una suscripción activa.';
      modalRef.componentInstance.textoAceptar = 'Aceptar';
      modalRef.result.then((result: any) => { }, (reason: any) => { });
      return;
    }
    this.loading.show();
    this.suscripcionesService.planActual().subscribe({
      next: (res: any) => {
        this.loading.hide();
        const modalRef = this.modalService.open(SuscripcionesInformacionPlanesComponent, { size: 'xl', scrollable: true, centered: true });
        modalRef.componentInstance.suscripcionPrevia = res.suscripcion;
        modalRef.componentInstance.suscripcionVPPrevia = res.suscripcionVP;
        modalRef.componentInstance.suscripcionDLGPrevia = res.suscripcionDLG;
        modalRef.result.then((result: any) => { }, (reason: any) => { });
      },
      error: (err: any) => {
        console.error(err);
        this.loading.hide();
        this.alerts.addAlert("Error al obtener la información.", "danger");
        this.sentryService.captureException(err.error);
      }
    });
  }
}
